import { render, staticRenderFns } from "./WindowController.vue?vue&type=template&id=c22750c0&"
import script from "./WindowController.vue?vue&type=script&lang=js&"
export * from "./WindowController.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthLogin: require('/home/ubuntu/frontend/components/Auth/Login.vue').default,AuthRegister: require('/home/ubuntu/frontend/components/Auth/Register.vue').default,LoggingIn: require('/home/ubuntu/frontend/components/LoggingIn.vue').default,AuthSendResetRequest: require('/home/ubuntu/frontend/components/Auth/SendResetRequest.vue').default,AuthVerifyCode: require('/home/ubuntu/frontend/components/Auth/VerifyCode.vue').default,AuthVerifyToken: require('/home/ubuntu/frontend/components/Auth/VerifyToken.vue').default,AuthForgotPasswordEmail: require('/home/ubuntu/frontend/components/Auth/ForgotPasswordEmail.vue').default,AuthForgotPasswordPhone: require('/home/ubuntu/frontend/components/Auth/ForgotPasswordPhone.vue').default,AuthVerifyResetPasswordCode: require('/home/ubuntu/frontend/components/Auth/VerifyResetPasswordCode.vue').default,AuthVerifyResetPasswordToken: require('/home/ubuntu/frontend/components/Auth/VerifyResetPasswordToken.vue').default})
