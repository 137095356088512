import { render, staticRenderFns } from "./Youtube.vue?vue&type=template&id=48069096&"
import script from "./Youtube.vue?vue&type=script&lang=js&"
export * from "./Youtube.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YoutubeVideo: require('/home/ubuntu/frontend/components/YoutubeVideo.vue').default})
